<template>
  <div
    class="menu"
    :class="{ bgColor: isShow }"
    :style="{ height: isShow ? '100%' : '0' }"
  >
    <div class="menu-icon">
      <font-awesome-icon
        :icon="faXmark"
        v-if="isShow"
        class="icon-mark"
        @click="isShow = false"
      />
      <!-- <div class="mr-3" v-if="!isShow">
        {{ $t("docStatus") }}
        <small
          :class="`badge badge-soft-${
            userStatus(activeStatus.value).class
          } ml-2`"
          >{{ $t(userStatus(activeStatus.value).text) }}</small
        >
      </div> -->
      <font-awesome-icon :icon="faCircleUser" @click="isShow = true" />
    </div>
    <div class="menu-content mt-5" v-if="isShow">
      <ul>
        <li>
          <router-link to="/">{{ $t("homePage") }}</router-link>
        </li>
        <li>
          <router-link to="/informationen">{{
            $t("personalDetails")
          }}</router-link>
        </li>
        <li>
          <router-link to="/tax-returns">{{ $t("myTaxReturn") }}</router-link>
        </li>
        <li>
          <router-link to="/kontoeinstellungen">{{
            $t("accountSettings")
          }}</router-link>
        </li>
        <li>
          <router-link to="/faq">{{ $t("faq") }}</router-link>
        </li>
        <li @click="logout" :style="{ cursor: 'pointer' }">
          <span>{{ $t("logout") }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faCircleUser,
      isShow: false,
      faXmark,
      statusList: [
        { value: 0, text: "waiting" },
        { value: 1, text: "reviewing" },
        { value: 4, text: "pendingConfirmation" },
        { value: 6, text: "paymentWaiting" },
        { value: 2, text: "completed" },
        { value: 5, text: "transmittedFa" },
        { value: 3, text: "canceled" },
      ],
    };
  },
  methods: {
    ...mapActions(["SET_LOGOUT_ACTION"]),
    logout() {
      this.SET_LOGOUT_ACTION();
      this.$router.push("/");
    },
    userStatus(val) {
      return val === 0
        ? { class: "info", text: "waiting" }
        : val === 1
        ? { class: "warning", text: "reviewing" }
        : val === 2
        ? { class: "success", text: "completed" }
        : val === 4
        ? { class: "secondary", text: "pendingConfirmation" }
        : val === 5
        ? { class: "primary", text: "transmittedFa" }
        : { class: "danger", text: "canceled" };
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
    activeStatus() {
      return this.statusList.find(
        (item) => item.value === this.activeUser.status
      );
    },
  },
};
</script>

<style lang="scss">
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 40px;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 9999;

  .menu-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
      height: 30px;
      cursor: pointer;
    }
  }

  .menu-content {
    ul {
      li {
        list-style: none;
        margin-bottom: 20px;
        text-decoration: underline;

        a {
          color: #161640;
        }
      }
    }
  }
}

.bgColor {
  background-color: #f1f8fa;
}
</style>